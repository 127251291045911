import {
  Breadcrumb,
  Layout,
  message,
  Button,
  Image,
  DatePicker,
  Form,
  Divider,
  Tag,
} from "antd";
import {
  HomeOutlined,
  DownloadOutlined,
  CheckOutlined,
  CloseOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
//import ExportXlsx from './ExportXlsx/ExportXlsx';
import ImportXlsx from "./ImportXlsx/ImportXlsx";

import {
  selectLoading,
  selectError,
  selectFormData,
} from "../../redux/survey/data/formDataSelector";
import {
  listFormData,
  deleteFormData,
  exportFormData,
  exportXlsxFormData,
  downloadFormData,
  downloadXlsxFormData,
  clearFormDataError,
} from "../../redux/survey/data/formDataActions";
import { Link } from "react-router-dom";
import moment from "moment";
import { clearLoading, setLoading } from "../../redux/project/projectActions";
import { getLevelsByForm } from "../../utils/utilData";

import { getSurveyForm } from "../../redux/survey/form/surveyFormActions";

import { FORM_FIELD_TYPES } from "../../utils/formTypes";

import { selectCurrentUser } from "../../redux/user/userSelector";
import { selectIsAuthenticated } from "../../redux/auth/authSelector";

import { DATEFORMAT } from "../../utils/utilData";
import FormItemsLocationsSimpleVersion from "../../utils/dataAnalysis/Locations/FormItemsLocationsSimpleVersion";

const { RangePicker } = DatePicker;
const { Content, Footer } = Layout;

/*
 * TODO -- Check changes introduces in code when changedField logic was introduced in
 * DataAnalysisForm component.
 *
 */

const SurveyFormData = (props) => {
  const intl = useIntl();

  //defining states and refs for reading xlsx file
  const inputXlsxFileRef = useRef(null);
  const [mapObj, setMapObj] = useState(null);
  const [nameNormalized_QB, setNameNormalized_QB] = useState(null);
  const [mainIdLabel, setMainIdLabel] = useState(null);
  const [hideProgress, setHideProgress] = useState(true);
  const [progressImportXlsx, setProgressImportXlsx] = useState({
    percent: 0,
    strokeColor: "green",
    steps: null,
  });
  const importedRef = useRef([]);
  const notImportedRef = useRef([]);
  const [totalRecords, setTotalRecords] = useState(null);
  const [disabledInputXlsxFile, setDisabledInputXlsxFile] = useState(true);
  const [dataSourceImportXlsxErrors, setDataSourceImportXlsxErrors] = useState(
    []
  );
  const [xlsxFile, setXlsxFile] = useState(null);
  const [importXlsxPhase, setImportXlsxPhase] = useState(null);
  const [chunkValue, setChunkValue] = useState(100);
  //end defining states and refs for reading xlsx file

  const checkPermissions = (requiredPermission) => {
    return (
      props.isAuthenticated &&
      requiredPermission &&
      props.currentUser &&
      !!props.currentUser?.permissions?.find(
        (permission) => permission === requiredPermission
      )
    );
  };

  const nonAPIFormData = (dataSchemas) => {
    return dataSchemas && dataSchemas[0]["nameNormalized"].includes("api_");
  };

  const { data, dataSchemas, count, formConfiguration } = props.formData;

  const formId = props.match.params.id;

  const [columns, setColumns] = useState([]);
  const [load, setLoad] = useState(true);
  const [exportMessage, setExportMessage] = useState(
    intl.formatMessage({ id: "label.request-export-csv" })
  );
  const [exportReady, setExportReady] = useState(true);
  const [exportIcon, setExportIcon] = useState(<DownloadOutlined />);
  const [importMessageXlsx, setImportMessageXlsx] = useState(
    intl.formatMessage({ id: "label.request-import-xlsx" })
  );
  const [importReadyXlsx, setImportReadyXlsx] = useState(true);
  const [importIconXlsx, setImportIconXlsx] = useState(<FileExcelOutlined />);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [sorter, setSorter] = useState({});

  const [form] = Form.useForm();
  const onFinish = (values) => {
    let filterSearch = {};
    if (
      "dateRange" in values &&
      values.dateRange !== undefined &&
      values.dateRange !== null
    ) {
      filterSearch.dateStart = [values.dateRange[0].format(DATEFORMAT)];
      filterSearch.dateEnd = [values.dateRange[1].format(DATEFORMAT)];
    }

    // if (values.regionCode) {
    //   const regionValues = values.regionCode?.split(";");
    //   filterSearch.idLevel = [regionValues[0]];
    //   filterSearch.regionCode = [regionValues[1]];
    // } else {
    //   filterSearch.regionCode = [values.regionCode];
    // }

    filterSearch.idLevel = [values.idLevel];
    filterSearch.regionCode = [values.regionCode];

    setFilters(filterSearch);
    props.listFormData(
      setColumns,
      intl,
      handleDelete,
      formId,
      props.history,
      pagination,
      filterSearch,
      sorter
    );
  };

  function disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf("day");
  }

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  const [geoLevels, setGeoLevels] = useState([]);

  useEffect(() => {
    if (geoLevels.length === 0) {
      dispatch(setLoading());
      dispatch(getLevelsByForm(formId)).then((data) => {
        if (data) {
          if (geoLevels.length === 0) {
            const loadedRegions = [...geoLevels];
            data.map((geoRegion) =>
              loadedRegions.push({
                label: geoRegion.name,
                value: geoRegion.idlevel + ";" + geoRegion.code,
              })
            );
            setGeoLevels(loadedRegions);
          }
        }
        dispatch(clearLoading());
      });
    }

    if (props.error) {
      const error = () => {
        message.error(props.error).then(props.clearFormDataError());
      };
      error();
      setLoad(false);
    }
    if (load) {
      props.listFormData(setColumns, intl, handleDelete, formId, props.history);
    }

    // eslint-disable-next-line
  }, [props.error]);

  const handleSort = async (column, sortDirection) => {
    let sortUpdate = {
      field: column.sortField,
      order: sortDirection,
    };
    setSorter(sortUpdate);
    props.listFormData(
      setColumns,
      intl,
      handleDelete,
      formId,
      props.history,
      pagination,
      filters,
      sorter
    );
  };
  const handlePageChange = (page, totalRows) => {
    let paginationUpdate = {
      ...pagination,
      current: page,
    };
    setPagination(paginationUpdate);
    props.listFormData(
      setColumns,
      intl,
      handleDelete,
      formId,
      props.history,
      paginationUpdate,
      filters,
      sorter
    );
  };
  const handlePerRowsChange = (currentRowsPerPage, currentPage) => {
    let pagination = {
      current: currentPage,
      pageSize: currentRowsPerPage,
    };
    setPagination(pagination);
    props.listFormData(
      setColumns,
      intl,
      handleDelete,
      formId,
      props.history,
      pagination,
      filters,
      sorter
    );
  };

  const handleDelete = (formId, id) => {
    props.deleteFormData(props.history, formId, id);
  };

  const handleExport = () => {
    //closing error report from Data Import.
    setDataSourceImportXlsxErrors([]);

    const values = form?.getFieldsValue();

    let filterSearch = {};
    if (
      "dateRange" in values &&
      values.dateRange !== undefined &&
      values.dateRange !== null
    ) {
      filterSearch.dateStart = [values.dateRange[0].format(DATEFORMAT)];
      filterSearch.dateEnd = [values.dateRange[1].format(DATEFORMAT)];
    }
    filterSearch.regionCode = [values.regionCode];
    setFilters(filterSearch);
    setExportMessage(
      intl.formatMessage({ id: "label.request-export-progress" })
    );
    if (count > 2000) {
      message.info(intl.formatMessage({ id: "label.request-export-long" }), 10);
    }
    setExportReady(false);
    const waitForDownload = async (exportData) => {
      const response = await props.downloadFormData(
        props.history,
        formId,
        exportData.filename
      );
      if (response && response.hasOwnProperty("data")) {
        message.info(intl.formatMessage({ id: "label.request-export-ready" }));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${exportData.filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        message.error(intl.formatMessage({ id: "label.request-export-error" }));
      }
    };

    props
      .exportFormData(props.history, formId, filterSearch)
      .then((exportData) => {
        waitForDownload(exportData).then(() => {
          setExportMessage(
            intl.formatMessage({ id: "label.request-export-completed" })
          );
          setExportReady(true);
          setExportIcon(<CheckOutlined />);
          const timer = (ms) => new Promise((res) => setTimeout(res, ms));
          timer(5000).then(() => {
            setExportMessage(
              intl.formatMessage({ id: "label.request-export-csv" })
            );
            setExportIcon(<DownloadOutlined />);
          });
        });
      });
  };

  const ExpandedComponent = ({ data }) => {
    const columns = [];
    if (dataSchemas.length > 1) {
      for (let i = 0; i < dataSchemas[1]["columnFormNames"].length; i++) {
        if (i !== 1 && i !== 3) {
          let selectorValue = dataSchemas[1]["columnNormalizedNames"][i];
          columns.push({
            name: dataSchemas[1]["columnFormNames"][i],
            selector: (row) => {
              return row[selectorValue];
            },
            sortable: false,
            cell: (row) => {
              let dataRecord = data[dataSchemas[1]["nameNormalized"]];
              let renderValue = row[selectorValue];
              const dataType = dataSchemas[1]["dataTypes"][i];
              if (renderValue && dataType === "JSON") {
                const val = renderValue["value"] !== undefined ? renderValue['value'] : renderValue;
                const values = JSON.parse(val);
                //const values = JSON.parse(renderValue['value']);
                renderValue = [];

                let photoValues = []
                for (let j = 0; j < formConfiguration.formFields.length; j++) {
                  if (formConfiguration.formFields[j].type === FORM_FIELD_TYPES.QUESTION_BLOCK) {
                    for (let k = 0; k < formConfiguration.formFields[j].formFields.length; k++) {
                        if (formConfiguration.formFields[j].formFields[k].nameNormalized === selectorValue &&
                            formConfiguration.formFields[j].formFields[k].type === FORM_FIELD_TYPES.PHOTO_CHECKBOX){
                          photoValues = formConfiguration.formFields[j].formFields[k].photoValues
                          break
                        }
                    }
                  }
                }


                for (let j = 0; j < values.length; j++) {
                  if (photoValues.length > j ) {
                    if (values[j].toString() === 'true'){
                      renderValue.push(<Image width={150} src={photoValues[j]} />);
                    }
                  }else{
                    renderValue.push(<Tag key={j}>{values[j].toString()}</Tag>);
                  }
                }
              } else if (renderValue && dataType === "BOOLEAN") {
                renderValue = renderValue.toString();
              } else if (renderValue && dataType === "DATE") {
                renderValue = moment(renderValue).format("YYYY-MM-DD");
              } else if (renderValue && dataType === "TIME") {
                renderValue = moment(renderValue).format("HH:mm:ss");
              } else if (renderValue && dataType === "PHOTO") {
                renderValue = <Image width={150} src={`data:${renderValue}`} />;
              }
              return dataRecord && dataRecord.length >= 1 ? (
                <div style={{ minWidth: 60 }}>{renderValue}</div>
              ) : null;
            },
          });
        }
      }
      return (
        <DataTable
          columns={columns}
          highlightOnHover
          responsive={true}
          striped={true}
          dense={true}
          noHeader={true}
          data={data[dataSchemas[1]["nameNormalized"]]}
        />
      );
    }
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#bfbfbf",
        fontWeight: "bolder",
      },
    },
  };

  //functions and useEffects for importing xlsx data
  //---------------------------------------------------------

  //setMapObj - used as argument of readXlsxFile
  useEffect(
    function ue_setMapObj() {
      if (!dataSchemas || !formConfiguration) return;

      //setting mapObj, nameNormalized_QB, mainIdLabel
      const formFields = formConfiguration.formFields;
      const formNamesInFormFields = formFields.map(
        (formField) => formField.name
      );
      const columnFormNames = dataSchemas[0].columnFormNames;
      const columnNormalizedNames = dataSchemas[0].columnNormalizedNames;
      const selectedColumnsObj = columnFormNames
        .map((columnFormName, index) => {
          if (
            ![...formNamesInFormFields, "Last Updated"].includes(columnFormName)
          ) {
            return {
              columnFormName: columnFormName,
              columnNormalizedName: columnNormalizedNames[index],
            };
          } else {
            return null;
          }
        })
        .filter((obj) => obj !== null);

      let mapObj_ = {};
      selectedColumnsObj.forEach((obj) => {
        mapObj_[obj.columnFormName] = obj.columnNormalizedName;
      });

      setNameNormalized_QB(undefined);
      setMainIdLabel(undefined);
      formFields.forEach((formField) => {
        switch (formField.type) {
          case FORM_FIELD_TYPES.QUESTION_BLOCK:
            //
            setNameNormalized_QB(formField.nameNormalized);
            setMainIdLabel(() => {
              const regExp = new RegExp("^p_[0-9].*_id$", "g");
              return dataSchemas[1].columnNormalizedNames.find(
                (columnNormalizedName) => regExp.test(columnNormalizedName)
              );
            });
            //
            const formFieldsQB = formField.formFields;
            let Qb_objects = {};
            formFieldsQB.forEach(
              (formFieldQB) =>
                (Qb_objects[formFieldQB.name] = formFieldQB.nameNormalized)
            );
            mapObj_[formField.name] = {
              [formField.nameNormalized]: Qb_objects,
            };
            break;
          default:
            mapObj_[formField.name] = formField.nameNormalized;
        }
      });

      setMapObj(mapObj_);

      //eslint-disable-next-line
    },
    [dataSchemas, formConfiguration]
  );

  useEffect(() => {
    if (mapObj === null || nameNormalized_QB === null || mainIdLabel === null) {
      setDisabledInputXlsxFile(true);
    } else {
      setDisabledInputXlsxFile(false);
    }

    //eslint-disable-next-line
  }, [mapObj, nameNormalized_QB, mainIdLabel]);

  /**
   * Allowed date formats that can be used in moment(data, format, true).isValid()
   */
  const MOMENT_VALID_DATE_FORMATS = [
    "YYYY-MM-DD",
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
    "YYYY-MM-DDTHH:mm:ss.SSS",
    "YYYY-MM-DDTHH:mm:ss",
    "YYYY-MM-DDHH:mm:ss",
    "MM/DD/YYYY",
    "MM/DD/YYYYHH:mm:ss",
    "MM/DD/YYYYHH:mm:ss.SSS",
    "MM/DD/YYYYHH:mm:ss.SSSZ",
    "MM/DD/YYYYHH:mm:ssZZ",
    "MM/DD/YYYYHH:mm:ssZ",
    "DD/MM/YYYY",
  ];

  const workerReadXlsxAndValidateDataRef = useRef();
  const workerImportXlsxDataRef = useRef();
  useEffect(
    function ue_importXlsxPhases() {
      if (importXlsxPhase === null) return;

      let workerObj, scriptURL;
      switch (importXlsxPhase["phase"]) {
        case "inputXlsxFileRef.event.change":
          const file = inputXlsxFileRef.current.files[0];
          const reader = new FileReader();
          reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            workerObj = {
              eventDataData: data,
              mapObj: mapObj,
              dataSchemas: dataSchemas,
              MOMENT_VALID_DATE_FORMATS: MOMENT_VALID_DATE_FORMATS,
              DATEFORMAT: DATEFORMAT,
            };
            scriptURL = "/web-workers/workerReadXlsxAndValidateData.js";
            workerReadXlsxAndValidateDataRef.current = new Worker(scriptURL);
            workerReadXlsxAndValidateDataRef.current.postMessage(workerObj);
            workerReadXlsxAndValidateDataRef.current.onmessage = (event) => {
              switch (event.data.event) {
                case "readXlsx.errors":
                  message.error(
                    "Data were not read from file. Check console to see errors.",
                    10
                  );
                  console.log(event.data.errors);
                  setDataSourceImportXlsxErrors([]);
                  setImportMessageXlsx(
                    intl.formatMessage({ id: "label.request-import-xlsx" })
                  );
                  setImportReadyXlsx(true);
                  setImportIconXlsx(<CheckOutlined />);

                  //finish the import process
                  setImportXlsxPhase(null);
                  break;
                case "xlsxReadData.validation.errors":
                  setDataSourceImportXlsxErrors(() =>
                    event.data.errors.map((item, index) => {
                      let newItem = JSON.parse(JSON.stringify(item));
                      newItem.key = (index + 1).toString();
                      newItem.errors = intl.formatMessage(
                        {
                          id: "msg.SurveyFormsData.xlsxReadData.validation.errors",
                        },
                        {
                          columnName: item["errors"]["columnName"],
                          value: item["errors"]["value"],
                        }
                      );
                      return newItem;
                    })
                  );

                  setImportReadyXlsx(true);
                  setImportMessageXlsx(
                    intl.formatMessage({
                      id: "label.request-import-not-completed",
                    })
                  );
                  setImportIconXlsx(<CloseOutlined />);
                  message
                    .error(
                      intl.formatMessage({
                        id: "msg.request-import-xlsx-invalid-data",
                      }),
                      5
                    )
                    .then(() => {
                      setImportMessageXlsx(
                        intl.formatMessage({ id: "label.request-import-xlsx" })
                      );
                      setImportIconXlsx(<FileExcelOutlined />);
                    });
                  //finish the import process
                  setImportXlsxPhase(null);
                  break;
                case "xlsxReadData.validation.success":
                  setImportMessageXlsx(
                    intl.formatMessage({
                      id: "label.request-import-xlsx-file-successfully-read",
                    })
                  );
                  setImportMessageXlsx(
                    intl.formatMessage({ id: "label.request-import-progress" })
                  );
                  setHideProgress(false);
                  setImportXlsxPhase({
                    phase: "xlsxFile.read",
                    rowsXlsx: event.data.rowsXlsx,
                  });
                  break;
                default:
                  message.error(event.data.errors, 10);
                  console.log(event.data.errors);
                  setDataSourceImportXlsxErrors([]);
                  setImportMessageXlsx(
                    intl.formatMessage({ id: "label.request-import-xlsx" })
                  );
                  setImportReadyXlsx(true);
                  setImportIconXlsx(<CheckOutlined />);
                  //finish the import process
                  setImportXlsxPhase(null);
              }

              //closing worker
              workerReadXlsxAndValidateDataRef.current.terminate();
              workerReadXlsxAndValidateDataRef.current = null;
            };
          };
          reader.readAsArrayBuffer(file);
          break;
        case "xlsxFile.read":
          //closing worker
          /* workerReadXlsxAndValidateDataRef.current?.terminate();
                workerReadXlsxAndValidateDataRef.current = null;                 */

          if (hideProgress) setHideProgress(false);

          notImportedRef.current = [];
          importedRef.current = [];

          scriptURL = "/web-workers/workerImportXlsxData.js";
          workerObj = {
            event: "init",
            rowsXlsx: importXlsxPhase.rowsXlsx,
            nameNormalized_QB: nameNormalized_QB,
            mainIdLabel: mainIdLabel,
            baseURL: process.env.REACT_APP_API_ENDPOINT,
            localStorageToken: localStorage.token,
            formConfiguration: formConfiguration,
            FORM_FIELD_TYPES: FORM_FIELD_TYPES,
            formId: formId,
            chunkValue: JSON.parse(JSON.stringify(chunkValue)),
          };
          workerImportXlsxDataRef.current = new Worker(scriptURL);
          workerImportXlsxDataRef.current.postMessage(workerObj);
          workerImportXlsxDataRef.current.onmessage = (event) => {
            switch (event.data.event) {
              case "importXlsx.setTotalRecords":
                setTotalRecords(event.data.setTotalRecords);
                break;
              case "importXlsx.rowsXlsx.invalid":
                message.info(
                  intl.formatMessage({
                    id: "label.request-import-xlsx-file-no-data",
                  })
                );
                break;
              case "addFormDataXlsx.record.errors":
                notImportedRef.current = event.data.notImportedRefCurrent;
                setProgressImportXlsx(event.data.setProgressImportXlsx);
                break;
              case "addFormDataXlsx.record.success":
                importedRef.current = event.data.importedRefCurrent;
                setProgressImportXlsx(event.data.setProgressImportXlsx);
                break;
              case "importXlsx.finished":
                setImportReadyXlsx(true);
                setImportMessageXlsx(
                  intl.formatMessage({ id: "label.request-import-completed" })
                );
                setImportIconXlsx(<CheckOutlined />);
                message.info(
                  intl.formatMessage({ id: "label.request-import-completed" }),
                  10
                );

                props.listFormData(
                  setColumns,
                  intl,
                  handleDelete,
                  formId,
                  props.history
                );
                const timer = (ms) => new Promise((res) => setTimeout(res, ms));
                timer(5000).then(() => {
                  setHideProgress(true);
                  setProgressImportXlsx({
                    percent: 0,
                    strokeColor: "white",
                  });
                  setImportMessageXlsx(
                    intl.formatMessage({ id: "label.request-import-xlsx" })
                  );
                  setImportIconXlsx(<FileExcelOutlined />);

                  //closing workers
                  workerImportXlsxDataRef.current?.terminate();
                  workerImportXlsxDataRef.current = null;
                });
                break;
              default:
            }
          };

          setImportXlsxPhase(null);
          break;
        default:
        //do nothing
      }
    },
    //eslint-disable-next-line
    [importXlsxPhase]
  );

  useEffect(() => {
    const postMessageObj = {
      event: "update.chunkValue",
      chunkValue: JSON.parse(JSON.stringify(chunkValue)),
    };
    workerImportXlsxDataRef.current?.postMessage(postMessageObj);
  }, [chunkValue]);

  //reinitializing states after importing xlxs
  useEffect(
    function ue_reinitializingAfterImportXlsx() {
      if (
        importReadyXlsx &&
        notImportedRef.current.length > 0 &&
        totalRecords !== null
      ) {
        console.log("--------------------------------------");
        console.log("Not imported rows from XLSX file:");
        console.log(notImportedRef.current);
        console.log("--------------------------------------");
        const notImportedPercent = Number.parseFloat(
          (notImportedRef.current.length / totalRecords) * 100
        ).toFixed(2);
        const msg = intl.formatMessage(
          { id: "label.request-import-xlsx.not-imported-percent" },
          { notImportedPercent: notImportedPercent }
        );
        setDataSourceImportXlsxErrors(() => {
          return notImportedRef.current
            .map((item, index) => {
              return {
                key: (index + 1).toString(),
                id: item.idXlsx,
                errors: Array.isArray(item.errors)
                  ? item.errors.map((error) => error.message).join(" --|-- ")
                  : item.errors.toString(),
              };
            })
            .sort((a, b) => (a.id === b.id ? 0 : a.id < b.id ? -1 : 1));
        });

        const timer = (ms) => new Promise((res) => setTimeout(res, ms));
        timer(10000).then(() => {
          setHideProgress(true);
          setProgressImportXlsx({
            percent: 0,
            strokeColor: "white",
          });
        });

        message.error(msg, 10).then(() => {
          notImportedRef.current = [];
          importedRef.current = [];
          setTotalRecords(null);
        });
      }
    },
    //eslint-disable-next-line
    [importReadyXlsx, totalRecords]
  );
  //end functions and useEffects for importing xlsx data
  //---------------------------------------------------------

  return (
    <Layout className="site-layout">
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "10px 0" }}>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>
              <FormattedMessage id="menu.forms" defaultMessage="Forms" />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{formId}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>
              <FormattedMessage id="label.data" defaultMessage="Data" />:{" "}
              {dataSchemas ? dataSchemas[0]["name"] : ""}
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          {!nonAPIFormData(dataSchemas) ? (
            <Link to={`/forms/${formId}/data/new`}>
              <Button
                type="primary"
                size={"large"}
                style={{ marginBottom: 16 }}
              >
                <FormattedMessage
                  id="label.add-data"
                  defaultMessage="Add Data"
                />
              </Button>
            </Link>
          ) : (
            <></>
          )}
          <Button
            type="primary"
            size={"large"}
            style={{
              marginBottom: 16,
              marginLeft: 16,
              backgroundColor: "green",
            }}
            icon={exportIcon}
            loading={!exportReady}
            onClick={handleExport}
          >
            {exportMessage}
          </Button>
          <Button
            type="link"
            style={{ display: "flex", margin: "auto 10px", float: "right" }}
            onClick={() => {
              props.history.goBack();
            }}
          >
            <FormattedMessage id="label.return" defaultMessage="Return" />
          </Button>
          {/*Disabled for Now*/}
          {/*<ExportXlsx {...props} />*/}
          {checkPermissions("ROLE_PERMISSION_DATA_IMPORT") ? (
            <ImportXlsx
              inputXlsxFileRef={inputXlsxFileRef}
              disabledInputXlsxFile={disabledInputXlsxFile}
              importIconXlsx={importIconXlsx}
              importReadyXlsx={importReadyXlsx}
              checkPermissions={checkPermissions}
              importMessageXlsx={importMessageXlsx}
              progressImportXlsx={progressImportXlsx}
              hideProgress={hideProgress}
              dataSourceImportXlsxErrors={dataSourceImportXlsxErrors}
              xlsxFile={xlsxFile}
              setDataSourceImportXlsxErrors={setDataSourceImportXlsxErrors}
              setXlsxFile={setXlsxFile}
              setImportMessageXlsx={setImportMessageXlsx}
              setImportReadyXlsx={setImportReadyXlsx}
              setImportXlsxPhase={setImportXlsxPhase}
              setChunkValue={setChunkValue}
              chunkValue={chunkValue}
            />
          ) : null}

          <Divider orientation="left" plain orientationMargin="0">
            <FormattedMessage id="label.filters" defaultMessage="Filters" />
          </Divider>
          <Form
            layout="inline"
            name="project_form"
            size="large"
            initialValues={{ active: true }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="dateRange"
              label={intl.formatMessage({ id: "label.dates" })}
              rules={[
                {
                  required: false,
                  message: intl.formatMessage({ id: "msg.input-required" }),
                },
              ]}
            >
              <RangePicker format={DATEFORMAT} disabledDate={disabledDate} />
            </Form.Item>
            <FormItemsLocationsSimpleVersion
              form={form}
              required={false}
              style={{ minWidth: "300px" }}
            />
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={props.loading}>
                <FormattedMessage id="label.search" defaultMessage="Search" />
              </Button>
            </Form.Item>
          </Form>
          <Divider />

          <DataTable
            columns={columns?.filter(function (value, index, arr) {
              return nonAPIFormData(dataSchemas) ? index !== 1 : true;
            })}
            data={data}
            progressPending={props.loading}
            highlightOnHover
            responsive={true}
            striped={true}
            dense={false}
            noHeader={true}
            persistTableHead={true}
            fixedHeader={true}
            expandableRows={dataSchemas?.length > 1}
            expandableRowsComponent={ExpandedComponent}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            onSort={handleSort}
            sortServer
            customStyles={customStyles}
          />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  error: selectError(state),
  formData: selectFormData(state),
  currentUser: selectCurrentUser(state),
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps, {
  listFormData,
  deleteFormData,
  exportFormData,
  exportXlsxFormData,
  downloadFormData,
  downloadXlsxFormData,
  clearFormDataError,
  getSurveyForm,
})(SurveyFormData);
