import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Divider,
  Modal,
  Select,
} from "antd";
import { connect } from "react-redux";
import {
  selectError,
  selectIsAuthenticated,
} from "../../redux/auth/authSelector";
import {
  clearLoginError,
  login,
  registerNewUser,
  registerUser,
  requestResetPassword,
} from "../../redux/auth/authActions";
import { getCurrentUser } from "../../redux/user/userActions";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  COUNTRY_LIST,
  REFERRAL_SOURCES,
  INDUSTRY_FIELDS,
} from "./LOGIN_CONSTANTS";
import "./Login.css";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Login = (props) => {
  const intl = useIntl();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
  const [isModalRegisterNewVisible, setIsModalRegisterNewVisible] =
    useState(false);
  const [form] = Form.useForm();
  const [formRegister] = Form.useForm();
  const [formRegisterNew] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const roleOptions = [
    {label: 'ROLE_FREE_USER', value: 4},
    // {label: 'ROLE_USER', value: 2},
    // {label: 'ROLE_SUBSCRIBER', value: 3},
    // {label: 'ROLE_BASIC_USER', value: 5},
    // {label: 'ROLE_PREMIUM_USER', value: 6},
  ]

  const countryOptions = COUNTRY_LIST.map((countryItem) => {
    return { label: countryItem.name, value: countryItem.code };
  });

  const industryFieldOptions = INDUSTRY_FIELDS.map((item) => {
    return { label: item, value: item };
  });

  const referralSourcesOptions = REFERRAL_SOURCES.map((item) => {
    return { label: item, value: item };
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const response = await props.requestResetPassword(
        props.history,
        form.getFieldsValue()
      );

      if (response && response?.message) {
        message.success(response?.message);
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showRegisterModal = () => {
    setIsModalRegisterVisible(true);
  };
  const showRegisterNewModal = () => {
    setIsModalRegisterNewVisible(true);
  };

  const handleRegisterOk = async () => {
    try {
      setLoading(true);
      await formRegister.validateFields();
      const projectCode = formRegister.getFieldValue("projectCode");
      const response = await props.registerUser(
        props.history,
        formRegister.getFieldsValue(),
        projectCode
      );

      if (response && response?.status === 201) {
        message.success(intl.formatMessage({ id: "label.user-registered" }));
        formRegister.resetFields();
        setIsModalRegisterVisible(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterNewOk = async () => {
    try {
      setLoading(true);
      await formRegisterNew.validateFields();
      const response = await props.registerNewUser(
        props.history,
        formRegisterNew.getFieldsValue()
      );

      if (response && response?.status === 201) {
        message.success(intl.formatMessage({ id: "label.user-registered" }));
        formRegisterNew.resetFields();
        setIsModalRegisterNewVisible(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterCancel = () => {
    setIsModalRegisterVisible(false);
  };

  const handleRegisterNewCancel = () => {
    setIsModalRegisterNewVisible(false);
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      setLoading(false);
      props.getCurrentUser(props.history);
      props.history.push("/dashboard");
    }

    if (props.error) {
      const error = () => {
        if (props.error.error === 404) {
          if (props.error.message) {
            message.error(props.error.message).then(props.clearLoginError());
          } else {
            message
              .error(intl.formatMessage({ id: "label.invalid-username" }))
              .then(props.clearLoginError());
          }
        } else if (typeof props.error.errors === "object") {
          props.error.errors.map((errorMessage) =>
            message.error(errorMessage.message).then(props.clearLoginError())
          );
        } else {
          message
            .error(intl.formatMessage({ id: "msg.invalid-credentials" }))
            .then(props.clearLoginError());
        }
      };
      setLoading(false);
      error();
    }

    // eslint-disable-next-line
  }, [props.isAuthenticated, props.error]);

  const onFinish = (values) => {
    setLoading(true);
    props.login(props.history, values);
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  const onRegisterFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  const onRegisterFinishNewFailed = (errorInfo) => {
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  const filterOption = (input, option) => {
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Row>
      <Col className={"login-center"}>
        <h1>
          <FormattedMessage
            id="label.user-credentials"
            defaultMessage="User Credentials"
          />
        </h1>
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={
              <FormattedMessage id="label.username" defaultMessage="Username" />
            }
            name="username"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-username" }),
              },
            ]}
          >
            <Input autoCapitalize="off" />
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage id="label.password" defaultMessage="Password" />
            }
            name="password"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-password" }),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              <FormattedMessage id="label.submit" defaultMessage="Submit" />
            </Button>
          </Form.Item>

          <Divider />
          <Link to="#" style={{ float: "left" }} onClick={showModal}>
            <FormattedMessage
              id="label.forgot-password"
              defaultMessage="Forgot Password?"
            />
          </Link>
          <Link
            to="#"
            style={{ float: "right" }}
            onClick={showRegisterNewModal}
          >
            <FormattedMessage
              id="label.create-account"
              defaultMessage="Create Account"
            />
          </Link>
          <Divider />
          <Link
            to="#"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={showRegisterModal}
          >
            <FormattedMessage
              id="label.register-project-code"
              defaultMessage="Register with Project code"
            />
          </Link>
        </Form>
      </Col>
      <Modal
        title={intl.formatMessage({ id: "label.request-password-reset" })}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        confirmLoading={loading}
      >
        <Form
          layout="horizontal"
          name="request-password-reset"
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label={
              <FormattedMessage id="label.username" defaultMessage="Username" />
            }
            name="username"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-username" }),
              },
            ]}
          >
            <Input autoCapitalize="off" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={intl.formatMessage({ id: "label.register-project-code" })}
        open={isModalRegisterVisible}
        onOk={handleRegisterOk}
        onCancel={handleRegisterCancel}
        centered
        confirmLoading={loading}
      >
        <Form
          layout="horizontal"
          name="register-user"
          onFinishFailed={onRegisterFinishFailed}
          form={formRegister}
          labelCol={{ span: 7 }}
        >
          <Form.Item
            name="firstName"
            label={intl.formatMessage({ id: "label.first-name" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={intl.formatMessage({ id: "label.last-name" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={intl.formatMessage({ id: "label.email" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage id="label.password" defaultMessage="Password" />
            }
            name="password"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="label.confirm-password"
                defaultMessage="Confirm password"
              />
            }
            name="password2"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({ id: "label.mismatch-password" })
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="projectCode"
            label={intl.formatMessage({ id: "label.project-code" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input type="projectCode" />
          </Form.Item>
          <Form.Item
            name="job"
            label={intl.formatMessage({ id: "label.job" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company"
            label={intl.formatMessage({ id: "label.company" })}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label={intl.formatMessage({ id: "label.phone" })}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={intl.formatMessage({ id: "label.create-account" })}
        open={isModalRegisterNewVisible}
        onOk={handleRegisterNewOk}
        onCancel={handleRegisterNewCancel}
        centered
        confirmLoading={loading}
      >
        <Form
          layout="horizontal"
          name="register-user-new"
          onFinishFailed={onRegisterFinishNewFailed}
          form={formRegisterNew}
          labelCol={{ span: 10 }}
        >
          <Form.Item
            name="firstName"
            label={intl.formatMessage({ id: "label.first-name" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={intl.formatMessage({ id: "label.last-name" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={intl.formatMessage({ id: "label.email" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="countryCode"
            label={intl.formatMessage({ id: "label.country" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Select options={countryOptions} showSearch={true} filterOption={filterOption}/>
          </Form.Item>
          <Form.Item
            name="job"
            label={intl.formatMessage({ id: "label.job" })}
            // rules={[
            //   {
            //     required: true,
            //     message: intl.formatMessage({ id: "msg.input-required" }),
            //   },
            // ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="industryField"
            label={intl.formatMessage({ id: "label.industry_field" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Select options={industryFieldOptions} />
          </Form.Item>
          <Form.Item
            name="referralSource"
            label={intl.formatMessage({ id: "label.referralSource" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "msg.input-required" }),
              },
            ]}
          >
            <Select options={referralSourcesOptions} />
          </Form.Item>
          <Form.Item
            name="company"
            label={intl.formatMessage({ id: "label.company" })}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label={intl.formatMessage({ id: "label.phone" })}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label={intl.formatMessage({ id: "label.role" })}
            initialValue={4}
            hidden
          >
            <Select 
              disabled
              options={roleOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  error: selectError(state),
});

export default connect(mapStateToProps, {
  login,
  getCurrentUser,
  clearLoginError,
  requestResetPassword,
  registerUser,
  registerNewUser,
})(Login);
