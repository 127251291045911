import { useEffect, useRef, useState, useContext } from "react";
import { useIntl } from "react-intl";

import MapboxGLMapInstanceChoropleth2 from "./VAMap/MapboxGLMapInstanceChoropleth2";
import ShowMessages from "../CustomMessages/ShowMessages";

import { DataAnalysisFormContext } from "../../../components/dataAnalysis/DataAnalysisForm";

/**
 * Data type definition of the argument passed to {@link PlotChoroplethMapMapBoxGlJs2}.
 *
 * @typedef {Object} PropsChoroplethMap
 * @property {analysisRawData} analysisRawData
 * @property {Function} setLoadingAnalysis
 * @property {Number} plotHeight
 * @property {Function} scrollToAnchor
 * @property {Number} analysisId
 * @property {String} analysisType
 */

/**
 * Creates a react component to represent a Choropleth map.
 *
 * @param {PropsChoroplethMap} props The argument passed to the react component.
 * @return {JSX} The `jsx` data that represents the react component.
 */
const PlotChoroplethMapMapBoxGlJs2 = () => {
  const intl = useIntl();

  //-------------------------------------------------------
  //variables and functions from props and context - start
  //-------------------------------------------------------
  const {
    analysisId,
    analysisRawData,
    analysisType,
    plotHeight,
    scrollToAnchor,
    setLoadingAnalysis,
    setValuePopupQuery,
    userGeoArea,
  } = useContext(DataAnalysisFormContext);
  //-------------------------------------------------------
  //variables and functions from props and context - end
  //-------------------------------------------------------

  const plotHeightCorrection = 40;
  const mapPlotHeight = plotHeight - plotHeightCorrection;
  const mapRef = useRef();
  const [messagesList, setMessagesList] = useState([]);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  /**
   * Data type definition that represents the data generated when a map control event is fired.
   *
   * @typedef {Object} LastControlEvent
   * @property {String} controlName The name of map control that had changed.
   * @property {Number|String|JSONStringify} data The data generated by the change in the map control.
   * @example Data generated when  "colorSchemesControl" changes.<caption></caption>
   *
   * const lastControlEvent = {
   *         controlName: "colorSchemesControl",
   *         data: "[\"#800000\",\"#ffa84f\",\"#ff0000\",\"#8ecb6d\",\"#eb4e2c\",\"#F3F3F3\",
   *                 \"#0059A9\",\"#A9A9AD\"]";
   */
  const [lastControlEvent, setLastControlEvent] = useState(null);

  /**
   * Initializes the map.
   */
  useEffect(
    function initMap() {
      //argument for setting params
      const paramsArg = {
        mapPlotHeight: mapPlotHeight,
        rawData: analysisRawData,
        intl: intl,
        setLastControlEvent: setLastControlEvent,
        analysisId: analysisId,
        analysisType: analysisType,
        boundsUser: userGeoArea.geojson,
        setIsMapLoaded: setIsMapLoaded,
        setValuePopupQuery: setValuePopupQuery,
      };

      mapRef.current = new MapboxGLMapInstanceChoropleth2(paramsArg);

      setLoadingAnalysis(false);

      scrollToAnchor("plotAnchor");

      setMessagesList(mapRef.current.params.messagesList);
    },
    //eslint-disable-next-line
    []
  );

  /**
   * Handles changes in map controls.
   *
   */
  useEffect(
    function handleControlsChange() {
      if (lastControlEvent !== null) {
        mapRef.current.handleControlsChange(lastControlEvent);
      }
    },
    //eslint-disable-next-line
    [lastControlEvent]
  );

  const { data: dataRecords } = analysisRawData;
  /**
   * Handles `dataRecords` changes after applying filters.
   *
   * @param {DataRecords} dataRecord The data sent back from backend after applying filters.
   */
  useEffect(
    function handleApplyFiltersEvent() {
      if (isMapLoaded) {
        mapRef.current.handleApplyFiltersEvent(dataRecords);

        setLoadingAnalysis(false);

        scrollToAnchor("plotAnchor");

        setMessagesList(mapRef.current.params.messagesList);
      }
    },
    //eslint-disable-next-line
    [dataRecords]
  );

  //jsx
  return (
    <div id={"plotAnchor"}>
      <div>
        <h1 style={{ float: "left" }}>{mapRef.current?.params?.mapTitle}</h1>
        <ShowMessages isDataPlotted={isMapLoaded} messagesList={messagesList} />
      </div>
      <div
        className="map-container"
        id="map-container-id"
        style={{ height: mapPlotHeight, clear: "both" }}
      />
    </div>
  );
};

export { PlotChoroplethMapMapBoxGlJs2 };
